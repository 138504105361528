import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Analytics from '../../../analytics/Analytics';
import Ad from '../../../models/banner/Ad';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import UIStore from '../../../store/UIStore';
import AdZones from '../../../types/AdZones';
import LinkType from '../../../types/LinkType';
import { ViewBreakpointMinWidth } from '../../../types/ViewBreakpoint';
import BannerType from '../../../types/banner/BannerType';
import BannerVideoType from '../../../types/banner/BannerVideoType';
import MaybeExternalLink from '../../common/MaybeExternalLink';
import ProductCode from '../../product/ProductCode';
import ProductPrice from '../../product/ProductPrice';
import ProductImageSign from '../../product/ProductSign';
import ProductSignDiscount from '../../product/ProductSignDiscount';
import ProductSigns from '../../product/ProductSigns';
import useBannerInteractions from './hook';

const Banner = ({
  configStore,
  uiStore,
  analytics,
  banner,
  bannerZone,
  className,
  isMobileBanner,
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const [handleInteraction, handleInteractionDown] = useBannerInteractions();

  const sendView = () => {
    analytics.promoView([
      {
        bannerZone,
        banner,
      },
    ]);
  };

  /**
   * Simulates componentWillMount lifecycle method.
   * Perhaps there's a better solution.
   */
  if (!isMounted) {
    !configStore.analytics.ga4.enabled && banner && sendView();
  }

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const sendPromotionClick = () => {
    analytics.promotionClick([
      {
        bannerZone,
        banner,
      },
    ]);
  };

  const renderBannerWrapper = () => {
    const bannerWrapper = isMobileBanner
      ? 'Banner__wrapper-mobile'
      : 'Banner__wrapper';

    const type = banner.is_external
      ? LinkType.EXTERNAL_LINK
      : LinkType.INTERNAL_LINK;

    const Wrapper = ({ children }) => (
      <div className={bannerWrapper}>
        {banner.link_url ? (
          <MaybeExternalLink
            accessible={false}
            to={banner.link_url}
            type={type}
            onMouseDown={handleInteractionDown}
            onTouchStart={handleInteractionDown}
            onClick={handleInteraction}
          >
            {children}
          </MaybeExternalLink>
        ) : (
          children
        )}
      </div>
    );

    return (
      <Wrapper>
        {renderContent()}
        {renderBannerOverlay()}
      </Wrapper>
    );
  };

  const renderContent = () => {
    return banner.type === BannerType.VIDEO ? renderVideo() : renderImage();
  };

  const renderVideo = () => {
    return (
      <div className="Banner__video-container">
        {banner.video_embed_style === BannerVideoType.FILE
          ? renderVideoFile()
          : renderEmbedMedia()}
      </div>
    );
  };

  const renderVideoFile = () => {
    return (
      <video
        controls
        loop
        preload="none"
        poster={banner.video_poster}
        width={banner.video_poster_width}
        height={banner.video_poster_height}
      >
        <source src={banner.video_file} type={banner.video_mime_type} />
      </video>
    );
  };

  const renderEmbedMedia = () => {
    return (
      <iframe src={banner.video_link} loading="lazy" frameBorder="0"></iframe>
    );
  };

  const renderImage = () => {
    return (
      <picture>
        {banner.mobile_image && (
          <source
            media={`(max-width:${ViewBreakpointMinWidth.SM}px)`}
            srcSet={encodeURI(banner.mobile_image)}
          />
        )}
        <img
          className={
            isMobileBanner && banner.mobile_image
              ? 'Banner__background-mobile-image'
              : 'Banner__background-image'
          }
          src={banner.image}
          height={banner.image_height}
          width={banner.image_width}
          alt={banner.seo_description}
        />
      </picture>
    );
  };

  const getOverlayWidth = () => {
    const mobileWidth = uiStore.isMobile
      ? banner.mobile_image_width || banner.image_width
      : banner.image_width;

    return (
      banner.video_poster_width ||
      (uiStore.isMobile ? mobileWidth : banner.image_width) ||
      undefined
    );
  };

  const renderBannerOverlay = () => {
    const title = getBannerTitle(banner);
    const content = getBannerContent(banner);
    const wrapperAlign = banner.stylesAlign;
    const priceInformation = getBannerPriceInformation(banner);

    return (
      (title || content) && (
        <div
          className="Banner__overlay"
          style={{
            background: banner.overlay_background_color,
            color: banner.text_color || undefined,
            pointerEvents:
              banner.type === BannerType.VIDEO ? 'none' : undefined,
            textAlign: wrapperAlign.textAlign || undefined,
            width: getOverlayWidth(),
          }}
        >
          {banner.overlay_image && (
            <img src={banner.overlay_image} alt={banner.seo_description} />
          )}

          {getBannerContentWrapper(
            title,
            banner,
            priceInformation,
            content,
            wrapperAlign
          )}
        </div>
      )
    );
  };

  const getBannerTitle = (banner) => {
    if (!banner.title) return;

    return (
      <div className="Banner__title">
        {banner.manufacturer && (
          <div className="Banner__manufacturer">{banner.manufacturer}</div>
        )}
        <h3 style={banner.stylesTitle(isMobileBanner)}>{banner.title}</h3>
      </div>
    );
  };

  const getBannerContent = (banner) => {
    if (!banner.content) return;

    return (
      <div
        className="Banner__content"
        style={banner.stylesContent(isMobileBanner)}
      >
        {banner.content}
      </div>
    );
  };

  const getBannerDiscountText = (banner) =>
    banner.discount_text ? (
      <div className="Banner__discount-text">
        <ProductImageSign>
          <ProductSignDiscount discountText={banner.discount_text} />
        </ProductImageSign>
      </div>
    ) : null;

  const getBannerPriceInformation = (banner) =>
    banner.price_info ? (
      <div className="Banner__price-info">
        <ProductPrice priceInfo={banner.price_info} />
        <ProductSigns
          hasDiscount={banner.price_info.is_discount}
          dateAdded={banner.date_added}
          discountProps={{
            percent: banner.price_info.discount_percentage,
            withUpTo: false,
            singlePriceLabel: false,
          }}
        />
      </div>
    ) : (
      getBannerDiscountText(banner)
    );

  const getBannerContentWrapper = (
    title,
    banner,
    priceInformation,
    content,
    style
  ) => (
    <div
      className="Banner__content_wrapper"
      style={{
        justifyContent: style?.justifyContent,
      }}
    >
      <div className="Banner__texts">
        {title}
        {banner.product_id && <ProductCode code={banner.productCode} />}
        {priceInformation}
        {content}
      </div>
    </div>
  );

  const shouldShowScent = () => {
    return (
      (bannerZone === AdZones.MAIN_BANNER ||
        bannerZone === AdZones.FOOTER_BANNER) &&
      !!configStore.banner.scent.pattern &&
      (!hasVideo() || (hasVideo() && !configStore.banner.scent.onTop)) &&
      !(uiStore.isMobile && !configStore.banner.scent.shouldShowMobile)
    );
  };

  const getBannerScent = () => {
    let onTop = {};

    if (!shouldShowScent()) {
      return null;
    }

    if (configStore.banner.scent.onTop) {
      onTop.position = 'relative';
      onTop.top = '-50px';
    }
    onTop.backgroundPositionX = configStore.banner.scent.positionX;
    onTop.backgroundRepeat = configStore.banner.scent.repeat;

    return (
      <div
        className="Banner__scent"
        style={{
          backgroundImage: `url(${configStore.banner.scent.pattern})`,
          ...onTop,
        }}
      />
    );
  };

  const hasVideo = () => {
    return (
      banner.type === BannerType.VIDEO &&
      (!!banner.video_file || !!banner.video_link)
    );
  };

  const hasMedia = () => {
    const hasImage = !!banner.image;
    return hasImage || hasVideo();
  };

  return (
    <div
      className={classNames(
        'Banner',
        {
          'Banner--default-width': !hasMedia(),
          'Banner--text-color-override': !!banner.text_color,
        },
        className
      )}
      onClick={sendPromotionClick}
    >
      {renderBannerWrapper()}
      {getBannerScent()}
    </div>
  );
};

Banner.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  banner: modelOf(Ad).isRequired,
  bannerZone: PropTypes.string.isRequired,
  className: PropTypes.string,
  isMobileBanner: PropTypes.bool,
};

export default inject('configStore', 'uiStore', 'analytics')(observer(Banner));
