import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import { DateTime } from 'luxon';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Container,
  Alert,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import RouterPropTypes from 'react-router-prop-types';
import { isEqual } from 'lodash';

import RouteService from '../../../services/RouteService';
import HelperNavigation from '../../common/HelperNavigation';
import globalTranslations from '../../../i18n/globalTranslations';
import Paths from '../../../types/Paths';
import { modelOf } from '../../../prop-types';
import OrderStore from '../../../store/OrderStore';
import ContentForState from '../../loader/ContentForState';
import SEOTitle from '../../head/SEOTitle';
import PageTitle from '../../common/PageTitle';
import CountryStore from '../../../store/CountryStore';
import ProductImage from '../../product/ProductImage';
import ProductImageModel from '../../../models/ProductImage';
import UIStore from '../../../store/UIStore';
import Icon from '../../common/Icon';
import OrderStatusLabel from '../../order/OrderStatusLabel';
import OrderTotalClass from '../../../types/OrderTotalClass';
import Price from '../../product/Price';
import ProductWarranty from '../../product/ProductWarranty';
import UnexpectedError from '../../loader/UnexpectedError';
import MaybeExternalLink from '../../common/MaybeExternalLink';
import OrderCloneButton from '../../order/OrderCloneButton';
import ConfigStore from '../../../store/ConfigStore';
import ProductTypeClass from '../../../types/ProductTypeClass';
import AccountStore from '../../../store/AccountStore';

import styles from '../../../styles/_exports.scss';

const messages = defineMessages({
  'myAccount.orderTitle': {
    id: 'myAccount.orderTitle',
    defaultMessage: 'Order {orderId}',
  },
});

const productTotals = [OrderTotalClass.TOTAL];

const billingInfoTotals = [
  OrderTotalClass.TAX_FREE_TOTAL,
  OrderTotalClass.TAX_TOTAL,
  OrderTotalClass.TOTAL,
];

const OrderHeaders = {
  image: <FormattedMessage {...globalTranslations.productTitle} />,
  name: <FormattedMessage {...globalTranslations.productNameTitle} />,
  quantity: <FormattedMessage {...globalTranslations.quantityTitle} />,
  deliveredQuantity: (
    <FormattedMessage
      id="order.deliveredQuantityTitle"
      defaultMessage="Delivered qty"
    />
  ),
  unitPrice: <FormattedMessage {...globalTranslations.unitPriceTitle} />,
  totalSum: <FormattedMessage {...globalTranslations.totalPriceSentence} />,
};

const StatusHeaders = {
  date: <FormattedMessage id="order.dateTitle" defaultMessage="Date" />,
  status: <FormattedMessage {...globalTranslations.statusTitle} />,
  description: (
    <FormattedMessage
      id="order.statusDescriptionTitle"
      defaultMessage="Description"
    />
  ),
};

const QueryStatus = {
  NONE: 'NONE',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  NOT_FOUND: 'NOT_FOUND',
  UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
};

const MyAccountOrder = ({
  accountStore,
  configStore,
  countryStore,
  orderStore,
  uiStore,
  intl,
  match,
  routeService,
  code,
}) => {
  const [queryStatus, setQueryStatus] = useState(QueryStatus.NONE);

  useEffect(() => {
    loadOrder();
  }, []);

  const getCurrentOrderId = () => {
    return match.params.id;
  };

  const loadOrder = () => {
    setQueryStatus(QueryStatus.LOADING);

    orderStore
      .loadOrder(getCurrentOrderId(), code)
      .then(() => {
        setQueryStatus(QueryStatus.LOADED);
      })
      .catch((e) => {
        const status = e.response ? e.response.status : 500;
        const errorMap = {
          404: QueryStatus.NOT_FOUND,
          500: QueryStatus.UNEXPECTED_ERROR,
        };
        setQueryStatus(errorMap[status]);
      });
  };

  const getOrder = () => {
    return orderStore.orders.get(getCurrentOrderId());
  };

  const getBreadcrumbsPath = () => {
    return routeService.transformBreadCrumbs([
      {
        text: intl.formatMessage(globalTranslations.myAccount),
        url: Paths.MyAccount,
      },
      {
        text: intl.formatMessage(globalTranslations.myOrderHistoryTitle),
        url: Paths.MyAccountOrderList,
      },
      {
        text: intl.formatMessage(messages['myAccount.orderTitle'], {
          orderId: getCurrentOrderId(),
        }),
        url: Paths.MyAccountOrderList,
      },
    ]);
  };

  const createProductImage = (url, id) => {
    return url
      ? ProductImageModel.create({
          id: 0,
          product_id: id,
          sizes: {
            small: url,
          },
        })
      : null;
  };

  const renderPaymentAddressInfo = (address) => {
    return (
      <>
        <h5
          className="MyAccountOrder__detail-header"
          style={{
            fontWeight: styles['font-weight-bold'],
          }}
        >
          <FormattedMessage
            id="myAccount.billingAddressTitle"
            defaultMessage="Billing address"
          />
        </h5>
        {renderAddressInfo(address)}
      </>
    );
  };

  const renderAddressInfo = (address) => {
    return (
      <ul
        className="MyAccountOrder__address-info"
        style={{
          listStyle: 'none',
          paddingLeft: '0',
        }}
      >
        <li>{address.name}</li>
        {address.company && <li>{address.company}</li>}
        {address.care_of && <li>{address.care_of}</li>}
        <li>{address.street_address}</li>
        {address.street_address2 && <li>{address.street_address2}</li>}
        <li>
          {address.postalCode} {address.city}
        </li>
        {address.country_id && (
          <li>{countryStore.findCountry(address.country_id).name}</li>
        )}
      </ul>
    );
  };

  const renderProductHeaderCol = (label, sizes) => {
    const actualSizes = Object.assign(
      {
        xs: 4,
      },
      sizes
    );
    return (
      <Col
        className="MyAccountOrder__product-header"
        style={{
          fontSize: `${styles['font-size-lg']}`,
          fontWeight: `${styles['font-weight-bold']}`,
        }}
        {...actualSizes}
      >
        {label}
      </Col>
    );
  };

  const renderProductHeaderRow = () => {
    return (
      <Row
        className="MyAccountOrder__product-headers"
        style={{
          paddingTop: styles['spacer-2'],
          paddingBottom: styles['spacer-2'],
        }}
      >
        {renderProductHeaderCol(OrderHeaders.image, { md: 2, xl: 1 })}
        {renderProductHeaderCol(OrderHeaders.name, { md: 6, xl: 7 })}
        {renderProductHeaderCol(OrderHeaders.quantity, { md: 1 })}
        {renderProductHeaderCol(OrderHeaders.deliveredQuantity, { md: 1 })}
        {renderProductHeaderCol(OrderHeaders.unitPrice, { md: 1 })}
        {renderProductHeaderCol(OrderHeaders.totalSum, { md: 1 })}
      </Row>
    );
  };

  const renderStatusHeaderCol = (label, sizes) => {
    return (
      <Col
        className="MyAccountOrder__status-header"
        style={{
          fontSize: styles['font-size-lg'],
          fontWeight: styles['font-weight-bold'],
        }}
        {...sizes}
      >
        {label}
      </Col>
    );
  };

  const renderStatusHeaderRow = () => {
    return (
      <Row className="MyAccountOrder__status-headers">
        {renderStatusHeaderCol(StatusHeaders.date, { md: 3, xl: 2 })}
        {renderStatusHeaderCol(StatusHeaders.status, { md: 4, xl: 3 })}
        {renderStatusHeaderCol(StatusHeaders.description, { md: 5 })}
      </Row>
    );
  };

  const renderProductLink = (product) => {
    return product.isPhysicalProduct && product.product_url ? (
      <MaybeExternalLink to={product.product_url}>
        {product.name}
      </MaybeExternalLink>
    ) : (
      <span className="MyAccountOrder__products-name">{product.name}</span>
    );
  };

  const renderProductCode = (product) => {
    if (configStore.productCard.showProductCode || accountStore.isRetailer) {
      return (
        <span className="MyAccountOrder__products-code">
          {product.productCode}
        </span>
      );
    }
  };

  const renderPaymentLogo = () => {
    const order = getOrder();
    if (order.payment.method && order.payment.method.logo) {
      return (
        <img
          src={order.payment.method.logo}
          alt={order.payment.method.name}
          loading="lazy"
          style={{
            maxWidth: '70px',
          }}
        />
      );
    }
    return null;
  };

  const renderShippingLogo = () => {
    const order = getOrder();
    if (order.shipping.method && order.shipping.method.logo) {
      return (
        <img
          src={order.shipping.method.logo}
          alt={order.shipping.method.name}
          loading="lazy"
          style={{
            maxWidth: '70px',
          }}
        />
      );
    }
    return null;
  };

  const renderProductRow = (product) => {
    const { isMobile } = uiStore;

    return (
      <Row
        key={product.id}
        className="MyAccountOrder__product-row"
        style={{
          paddingTop: styles['spacer-2'],
          paddingBottom: styles['spacer-2'],
        }}
      >
        {!isMobile && (
          <Col className="MyAccountOrder__products-image" xs="2" xl="1">
            {product.isPhysicalProduct && (
              <MaybeExternalLink to={product.product_url}>
                <ProductImage
                  product={product}
                  productImage={createProductImage(
                    product.image_url,
                    product.id
                  )}
                  size="small"
                  forceAspectRatio={false}
                />
              </MaybeExternalLink>
            )}
          </Col>
        )}
        {isMobile && renderProductHeaderCol(OrderHeaders.name)}
        <Col xs="8" md="6" xl="7">
          <div
            className={'MyAccountOrder__products-name-wrapper'}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {renderProductLink(product)}
            {product &&
              product.type !== ProductTypeClass.SHIPPING_COST &&
              renderProductCode(product)}
          </div>
          {product.variations && product.variations.length > 0 && (
            <ul>
              {product.variations.map((variation, index) => (
                <li key={index}>{variation}</li>
              ))}
            </ul>
          )}
          {product.reference && (
            <div className="MyAccountOrder__products-reference">
              <FormattedMessage {...globalTranslations.yourReferenceTitle} />
              {': ' + product.reference}
            </div>
          )}
          {product.warranty_in_months > 0 && (
            <div className="MyAccountOrder__products-warranty">
              <ProductWarranty warranty={product.warranty_in_months} />
            </div>
          )}
        </Col>
        {product.isPhysicalProduct ? (
          <>
            {isMobile && renderProductHeaderCol(OrderHeaders.quantity)}
            <Col xs="8" md="1">
              {product.quantity_ordered} {product.stock_unit}
            </Col>
            {isMobile && renderProductHeaderCol(OrderHeaders.deliveredQuantity)}
            <Col xs="8" md="1">
              {product.quantity_delivered} {product.stock_unit}
            </Col>
            {isMobile && renderProductHeaderCol(OrderHeaders.unitPrice)}
            <Col
              xs="8"
              md="1"
              dangerouslySetInnerHTML={{ __html: product.final_unit_price }}
            />
          </>
        ) : !isMobile ? (
          <Col md="3" />
        ) : null}
        {isMobile && renderProductHeaderCol(OrderHeaders.totalSum)}
        <Col
          xs="8"
          md="1"
          dangerouslySetInnerHTML={{ __html: product.total_price }}
        />
      </Row>
    );
  };

  const renderTotals = (visibleTotalClasses) => {
    const order = getOrder();

    return (
      <div className="MyAccountOrder__totals">
        {order.totals
          .filter((total) => {
            return visibleTotalClasses.includes(total.class);
          })
          .map((totalsItem) => (
            <Row key={totalsItem.name}>
              <Col
                xs="8"
                className="MyAccountOrder__total-item"
                style={{
                  fontWeight: styles['font-weight-bold'],
                }}
              >
                {totalsItem.name}
              </Col>
              <Col
                xs="4"
                className="MyAccountOrder__total-value"
                style={{
                  padding: `0 ${styles['spacer-4']}`,
                }}
              >
                <Price price={totalsItem.value} />
              </Col>
            </Row>
          ))}
      </div>
    );
  };

  const renderInfoComponent = (title, content) => {
    return (
      <>
        <h5
          className="MyAccountOrder__detail-header"
          style={{
            fontWeight: styles['font-weight-bold'],
          }}
        >
          {title}
        </h5>
        <p>{content}</p>
      </>
    );
  };

  const renderPickUpPoint = (pickUpPoint) => {
    return (
      <div
        className="MyAccountOrder__detail-pickup"
        style={{
          marginBottom: styles['spacer'],
        }}
      >
        <div className="MyAccountOrder__detail-pickup-name">
          {pickUpPoint.name}
        </div>
        <div className="MyAccountOrder__detail-pickup-address">
          {pickUpPoint.address}
        </div>
        <div
          className="MyAccountOrder__detail-pickup-contact-info"
          style={{
            fontSize: styles['font-size-sm'],
          }}
        >
          {pickUpPoint.contact_info}
        </div>
      </div>
    );
  };

  const renderTracking = (tracking) => {
    return (
      <>
        <h5
          className="MyAccountOrder__detail-header"
          style={{
            fontWeight: styles['font-weight-bold'],
          }}
        >
          <FormattedMessage
            id="myAccount.trackingTitle"
            defaultMessage="Tracking order parcels"
          />
        </h5>
        {tracking.map((trackingOrder, index) => {
          return (
            <p key={trackingOrder.code}>
              <FormattedMessage
                id="myAccount.packageTitle"
                defaultMessage="Packet {parcelCount}: "
                values={{
                  parcelCount: index + 1,
                }}
              />{' '}
              <a href={trackingOrder.url} target="_blank" rel="noopener">
                <FormattedMessage
                  id="myAccount.packageToTracking"
                  defaultMessage="{trackingCode} (Go to tracking)"
                  values={{
                    trackingCode: trackingOrder.code,
                  }}
                />{' '}
                <Icon name="angle-double-right" />
              </a>
            </p>
          );
        })}
        <FormattedMessage
          id="myAccount.trackingDescription"
          defaultMessage="(If the tracking page notifies you that no orders were found, it may be that the parcel is not yet received at the delivery company)"
        />
      </>
    );
  };

  const shouldRenderPaymentAddress = () => {
    const order = getOrder();

    return !isEqual(order.shipping.address, order.payment.address);
  };

  const renderMessage = () => {
    let message = null;

    switch (queryStatus) {
      case QueryStatus.NOT_FOUND:
        message = (
          <Alert color="danger">
            <FormattedMessage
              id="order.orderNotFound"
              defaultMessage="Given code is not found"
            />
          </Alert>
        );
        break;
      case QueryStatus.UNEXPECTED_ERROR:
        message = <UnexpectedError />;
        break;
      case QueryStatus.NONE:
      case QueryStatus.LOADING:
      case QueryStatus.LOADED:
      default:
        break;
    }

    return message;
  };

  const orderId = getCurrentOrderId();

  const Wrapper = ({ children }) => {
    return !configStore.account.enableMyAccount &&
      ((!accountStore.loggedIn && code) || accountStore.loggedIn) ? (
      <Container className="MyAccountPage__container">{children}</Container>
    ) : (
      children
    );
  };

  return (
    <Wrapper>
      <HelperNavigation breadcrumbsPath={getBreadcrumbsPath()} />
      <SEOTitle
        title={{
          ...messages['myAccount.orderTitle'],
          values: { orderId },
        }}
      />
      {queryStatus && renderMessage()}
      <ContentForState
        state={queryStatus}
        forLoaded={() => {
          const order = getOrder();
          return (
            <div className="MyAccountOrder">
              <PageTitle
                className="MyAccountOrder__page-title"
                style={{
                  display: 'flex',
                }}
              >
                <FormattedMessage
                  {...messages['myAccount.orderTitle']}
                  values={{ orderId }}
                />{' '}
                <span
                  className="MyAccountOrder__status"
                  style={{
                    marginLeft: styles['spacer-2'],
                  }}
                >
                  <OrderStatusLabel status={order.last_status} />
                </span>
              </PageTitle>
              <Container
                className="MyAccountOrder__container"
                style={{
                  paddingLeft: '0',
                  paddingRight: '0',
                }}
              >
                <Row>
                  <Col xl="6" md="12">
                    <Card
                      style={{
                        marginTop: styles['spacer-2'],
                        marginBottom: styles['spacer'],
                      }}
                    >
                      <CardHeader
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          fontSize: styles['font-size-lg'],
                        }}
                      >
                        <FormattedMessage
                          id="myAccount.shippingDetailsTitle"
                          defaultMessage="Shipping details"
                        />
                        <FormattedMessage
                          id="myAccount.orderDate"
                          defaultMessage="Ordered on: {orderDate}"
                          values={{
                            orderDate: DateTime.fromISO(
                              order.date_purchased
                            ).toLocaleString(),
                          }}
                        />
                      </CardHeader>
                      <CardBody>
                        <Container
                          className="MyAccountOrder__shipping-details-container"
                          style={{
                            paddingLeft: '0',
                            paddingRight: '0',
                          }}
                        >
                          <Row>
                            <Col>
                              <h5
                                className="MyAccountOrder__detail-header"
                                style={{
                                  fontWeight: styles['font-weight-bold'],
                                }}
                              >
                                <FormattedMessage
                                  id="myAccount.shippingAddressTitle"
                                  defaultMessage="Shipping address"
                                />
                              </h5>
                              {renderAddressInfo(order.shipping.address)}
                              {order.seller_name &&
                                renderInfoComponent(
                                  <FormattedMessage
                                    {...globalTranslations.retailerTitle}
                                  />,
                                  order.seller_name
                                )}
                              {order.your_reference &&
                                renderInfoComponent(
                                  <FormattedMessage
                                    {...globalTranslations.yourReferenceTitle}
                                  />,
                                  order.your_reference
                                )}
                            </Col>
                            <Col>
                              <h5
                                className="MyAccountOrder__detail-header"
                                style={{
                                  fontWeight: styles['font-weight-bold'],
                                }}
                              >
                                <FormattedMessage
                                  id="myAccount.shippingMethodTitle"
                                  defaultMessage="Shipping method"
                                />
                              </h5>
                              <div
                                className="MyAccountOrder__method"
                                style={{
                                  marginBottom: styles['spacer-2'],
                                  display: 'flex',
                                }}
                              >
                                <div
                                  className="MyAccountOrder__method-logo"
                                  style={{
                                    maxWidth: '70px',
                                  }}
                                >
                                  {renderShippingLogo()}
                                </div>
                                <div className="MyAccountOrder__method-name">
                                  {order.shipping.method.name}
                                </div>
                              </div>
                              {order.shipping.pick_up_point &&
                                renderPickUpPoint(order.shipping.pick_up_point)}
                              {order.weight &&
                                renderInfoComponent(
                                  <FormattedMessage
                                    {...globalTranslations.weightTitle}
                                  />,
                                  order.weight
                                )}
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              {order.shipping.tracking.length > 0 &&
                                renderTracking(order.shipping.tracking)}
                            </Col>
                          </Row>
                        </Container>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl="6" md="12">
                    <Card
                      style={{
                        marginTop: styles['spacer-2'],
                        marginBottom: styles['spacer'],
                      }}
                    >
                      <CardHeader
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          fontSize: styles['font-size-lg'],
                        }}
                      >
                        <FormattedMessage
                          id="myAccount.paymentDetailsTitle"
                          defaultMessage="Payment details"
                        />
                        {order.receipt_url && (
                          <Button
                            tag="a"
                            href={order.receipt_url}
                            color="primary"
                            className="MyAccountOrder__payment-receipt-button"
                            target="_blank"
                            style={{
                              marginTop: `-${styles['spacer-4']}`,
                              marginBottom: `-${styles['spacer-3']}`,
                            }}
                          >
                            <Icon name="print" />{' '}
                            <FormattedMessage
                              id="myAccount.printReceiptSentence"
                              defaultMessage="Print a receipt of this order"
                            />
                          </Button>
                        )}
                      </CardHeader>
                      <CardBody>
                        <Container
                          className="MyAccountOrder__payment-details-container"
                          style={{
                            paddingLeft: '0',
                            paddingRight: '0',
                          }}
                        >
                          <Row>
                            <Col xs="12" md="6">
                              {shouldRenderPaymentAddress() &&
                                renderPaymentAddressInfo(order.payment.address)}

                              <h5
                                className="MyAccountOrder__detail-header"
                                style={{
                                  fontWeight: styles['font-weight-bold'],
                                }}
                              >
                                <FormattedMessage
                                  id="myAccount.billingMethodTitle"
                                  defaultMessage="Billing method"
                                />
                              </h5>
                              <div
                                className="MyAccountOrder__method"
                                style={{
                                  marginBottom: styles['spacer-2'],
                                  display: 'flex',
                                }}
                              >
                                <div
                                  className="MyAccountOrder__method-logo"
                                  style={{
                                    maxWidth: '70px',
                                  }}
                                >
                                  {renderPaymentLogo()}
                                </div>
                                <div className="MyAccountOrder__method-name">
                                  {order.payment.method.name}
                                </div>
                              </div>
                            </Col>
                            <Col xs="12" md="6">
                              {renderTotals(billingInfoTotals)}
                            </Col>
                          </Row>
                          {accountStore.loggedIn && (
                            <Row>
                              <Col className="MyAccountOrder__clone-button">
                                <OrderCloneButton order={getOrder()} />
                              </Col>
                            </Row>
                          )}
                        </Container>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card
                      style={{
                        marginTop: styles['spacer-2'],
                        marginBottom: styles['spacer'],
                      }}
                    >
                      <CardHeader
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          fontSize: styles['font-size-lg'],
                        }}
                      >
                        <FormattedMessage
                          {...globalTranslations.productsTitle}
                        />
                      </CardHeader>
                      <CardBody>
                        <Container
                          className="MyAccountOrder__products-container"
                          style={{
                            paddingLeft: '0',
                            paddingRight: '0',
                          }}
                        >
                          <div className="MyAccountOrder__products">
                            {!uiStore.isMobile && renderProductHeaderRow()}
                            {order.products.map((product) =>
                              renderProductRow(product)
                            )}
                          </div>
                          <Row
                            className="MyAccountOrder__products-totals-row"
                            style={{
                              marginTop: styles['spacer'],
                              borderTop: `2px solid ${styles['border-color']}`,
                              paddingTop: styles['spacer'],
                            }}
                          >
                            <Col md="9" />
                            <Col>{renderTotals(productTotals)}</Col>
                          </Row>
                        </Container>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card
                      style={{
                        marginTop: styles['spacer-2'],
                        marginBottom: styles['spacer'],
                      }}
                    >
                      <CardHeader
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          fontSize: styles['font-size-lg'],
                        }}
                      >
                        <FormattedMessage
                          id="myAccount.orderHistoryTitle"
                          defaultMessage="Shipping history"
                        />
                      </CardHeader>
                      <CardBody>
                        {!uiStore.isMobile && renderStatusHeaderRow()}
                        {order.status_history.map((entry, index) => (
                          <Row
                            key={`${entry.date}_${entry.status}_${index}`}
                            className="MyAccountOrder__status-history-entry"
                            style={{
                              padding: `${styles['spacer']} 0`,
                              borderTop: `1px solid #eee`,
                            }}
                          >
                            <Col md="3" xl="2">
                              {DateTime.fromISO(entry.date).toLocaleString(
                                DateTime.DATETIME_SHORT
                              )}
                            </Col>
                            <Col md="4" xl="3">
                              <OrderStatusLabel status={entry.status} />
                            </Col>
                            <Col md="5">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: entry.message,
                                }}
                              />
                            </Col>
                          </Row>
                        ))}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          );
        }}
      />
    </Wrapper>
  );
};

MyAccountOrder.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  countryStore: modelOf(CountryStore).isRequired,
  orderStore: modelOf(OrderStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  intl: intlShape.isRequired,
  match: RouterPropTypes.match.isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  code: PropTypes.string,
};

export default injectIntl(
  withRouter(
    inject(
      'accountStore',
      'configStore',
      'countryStore',
      'orderStore',
      'routeService',
      'uiStore'
    )(observer(MyAccountOrder))
  )
);
